<template>
    <base-panel @overlay-clicked="close"
                class="sidebar-container"
                panel-class="sidebar"
                ref="panel">
        <div class="header">
            <h1>
                {{ ucf$t('customers.new') }}
            </h1>
        </div>
        <form @submit.prevent="submit"
              class="body"
              ref="employeeForm">
            <h2>
                {{ ucf$t('settings.general_data') }}
            </h2>
            <div class="form-group">
                <label for="companyName">
                    {{ ucf$t("customers.name") }}
                </label>
                <input id="companyName"
                       name="companyName"
                       ref="companyName"
                       type="text"
                       v-model="formData.companyName">
            </div>
            <div class="form-group">
                <label for="companyTypeId">
                    {{ ucf$t("customers.type") }}
                </label>
                <multiselect :multiple="false"
                             :options="customerTypes"
                             :placeholder="ucf$t('customers.select_type')"
                             class="w-1/4 mr-4"
                             deselect-label=""
                             id="companyTypeId"
                             label="name"
                             name="companyTypeId"
                             ref="companyTypeId"
                             select-label=""
                             selected-label=""
                             track-by="id"
                             v-model="formData.type" />
            </div>
            <div class="form-row">
                <div class="form-group">
                    <label for="firstName">
                        {{ ucf$t("user.first_name") }}
                    </label>
                    <input id="firstName"
                           name="firstName"
                           ref="firstName"
                           type="text"
                           v-model="formData.firstName">
                </div>
                <div class="form-group">
                    <label for="middleName">
                        {{ ucf$t("user.middle_name") }}
                    </label>
                    <input id="middleName"
                           name="middleName"
                           ref="middleName"
                           type="text"
                           v-model="formData.middleName">
                </div>
                <div class="form-group">
                    <label for="lastName">
                        {{ ucf$t("user.last_name") }}
                    </label>
                    <input id="lastName"
                           name="lastName"
                           ref="lastName"
                           type="text"
                           v-model="formData.lastName">
                </div>
            </div>
            <div class="form-row">
                <div class="form-group">
                    <label for="phone">
                        {{ ucf$t("user.phone_number") }}
                    </label>
                    <input id="phone"
                           name="phone"
                           ref="phone"
                           type="text"
                           v-model="formData.phone">
                </div>
                <div class="form-group">
                    <label for="email">
                        {{ ucf$t("user.email") }}
                    </label>
                    <input id="email"
                           name="email"
                           ref="email"
                           type="text"
                           v-model="formData.email">
                </div>
            </div>

            <button class="primary">
                {{ ucf$t('customers.new') }}
            </button>
        </form>
    </base-panel>
</template>

<script>
    import moment from 'moment';
    import Multiselect from 'vue-multiselect';
    import BasePanel from '../components/BasePanel';

    export default {
        name: 'CustomerCreate',
        components: { BasePanel, Multiselect },
        data() {
            return {
                customerTypes: [],
                formData: {},
            };
        },
        methods: {
            close() {
                this.$router.replace({
                    name: 'customers.index',
                    query: { t: moment().format('HHmmssSSS') },
                });
            },
            submit() {
                const { formData } = this;
                formData.companyTypeId = this.formData.type ? this.formData.type.id : null;

                this.$http.post('/customers/create', formData)
                    .then(() => {
                        this.$router.replace({
                            name: 'customers.index',
                            query: { t: moment().format('HHmmssSSS') },
                        });
                    })
                    .catch((error) => {
                        this.handleErrors(error.response);
                    });
            },
        },
        beforeMount() {
            this.$http.get('/customer-types')
                .then((response) => {
                    this.customerTypes = response.data.data;
                });
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.$refs.panel.open();
            });
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.close();
            return next();
        },
    };
</script>
