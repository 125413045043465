import { roles } from "../../configuration/variables";
import ParameterService from "../../services/ParameterService";
import ParameterCreate from '../../views/ParameterCreate';
import ParameterEdit from '../../views/ParameterEdit';
import ParameterForm from '../../views/ParameterForm';
import ParameterIndex from '../../views/ParameterIndex';
import ParameterInfo from '../../views/ParameterInfo';
import ParameterSegment from '../../views/ParameterSegment';
import auth from '../middleware/_auth';
import role from '../middleware/_role';
import ModelBoundView from "../utils/ModelBoundView";

export default [
    {
        name: 'parameters.index',
        path: '/parameters',
        component: ParameterIndex,
        meta: {
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
            ],
        },
    },
    {
        name: 'parameters.create',
        path: '/parameters/create',
        component: ParameterCreate,
        children: [
            {
                name: 'parameters.create.info',
                path: 'info',
                component: ParameterInfo,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'parameters.create.form',
                path: 'form',
                component: ParameterForm,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'parameters.create.segments',
                path: 'segments',
                component: ParameterSegment,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                        ({ from, next }) => {
                            if (['parameters.create.info', 'parameters.create.segments'].includes(from.name)) {
                                return next();
                            }

                            return next({ name: 'parameters.create.info' });
                        },
                    ],
                },
            },
        ],
        meta: {
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
                ({ next }) => next({ name: 'parameters.create.info' }),
            ],
        },
    },
    {
        name: 'parameters.edit',
        path: '/parameters/:parameterId/edit',
        component: ModelBoundView(new ParameterService, ParameterEdit),
        children: [
            {
                name: 'parameters.edit.info',
                path: 'info',
                component: ParameterInfo,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'parameters.edit.form',
                path: 'form',
                component: ParameterForm,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'parameters.edit.segments',
                path: 'segments',
                component: ParameterSegment,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
        ],
        meta: {
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
                ({ to, next }) => next({ name: 'parameters.edit.info', params: to.params, query: to.query }),
            ],
        },
    },
]
